<template>
  <div class="magazines">
    <ag-table :data-source-api="getAllMagazines" :grid-options="gridOptions" :permissions="permissions" :rtl="false"
      :is-entities-response="false" @emitAddNewRecord="onCreateMagazine()">
    </ag-table>
    <tm-popup :submit="onMagazinePopupSubmit" :title="magazinePopupTitle" ref="magazinePopup">
      <div class="vx-row justify-start">
        <div class="vx-col w-full mb-2 mt-2">
          <tm-input class="w-full" name="رقم العدد" placeholder="رقم العدد" type="number" validation-rules="required"
            v-model="magazine.releaseNumber" />
        </div>

        <div class="vx-col w-full mb-2 mt-2">
          <tm-input class="w-full" name="رابط العدد" placeholder="رابط العدد" type="text"
            :validation-rules="{ required: true, regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/ }"
            v-model="magazine.link" />
        </div>
      </div>
    </tm-popup>
  </div>
</template>

<script>
import { RepositoryFactory } from '../zadi-repositories/zadiRepositoryFactory';
import AgTable from '../../shared/shared-components/ag-grid/AgTableServerSide.vue';
import AgTableBtnCell from "../../shared/shared-components/ag-grid/AgTableBtnCell";
import AgButton from "../../shared/shared-components/ag-grid/AgButton";
import TmPopup from "@/app/shared/shared-components/TmPopup";
import TmInput from "@/app/shared/shared-components/TmInput";

const zadiRepo = RepositoryFactory.get('zadiRepository');
export default {
  name: "Magazines",
  components: {
    AgTable,
    TmInput,
    AgTableBtnCell,
    TmPopup,
    AgButton
  },
  data() {
    return {
      gridApi: null,
      gridOptions: null,
      magazinePopupTitle: '',
      magazine: {
        releaseNumber: null,
        link: null,
      },
      permissions: {
        add: 'magazines_management',
        edit: 'magazines_management',
        delete: 'magazines_management',
      }
    };
  },
  methods: {
    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'العدد',
          field: 'releaseNumber',
          filter: 'agTextColumnFilter',
          width: 200,
          sortable: false
        },
        {
          headerName: 'الرابط',
          field: 'link',
          width: 150,
          sortable: false,
          cellRendererFramework: AgButton,
          valueGetter:
            function getObject(params) {
              return params;
            },
          cellRendererParams: {
            size: 'small',
            color: 'primary',
            name: 'الانتقال الى صفحة المجلة',
            type: 'gradient',
            click(valueGetter) {
              self.onOpenMagazinePage(valueGetter.data);
            }
          },
        },
        {
          headerName: 'الإجراءات',
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          sortable: false,
          cellRendererParams: {
            deleteRecord: function (id) {
              self.onDeleteMagazine(id);
            },
            editRecord: function (magazine) {
              self.onUpdateMagazine(magazine);
            },
            actions: ['edit', 'delete'],
            permissions: self.permissions
          },
        }
      ];
    },
    async onDeleteMagazine(magazineId) {
      await zadiRepo.removeMagazine(magazineId);
      await this.gridApi.refreshServerSideStore({ purge: true });
    },
    onOpenMagazinePage(magazine) {
      window.open(magazine.link, '_blank').focus();
    },
    onUpdateMagazine(magazine) {
      this.magazinePopupTitle = 'تعديل معلومات العدد';
      this.resetMagazineForm();
      Object.assign(this.magazine, magazine);
      this.$refs.magazinePopup.open();
    },
    onCreateMagazine() {
      this.magazinePopupTitle = 'إضافة عدد جديد';
      this.resetMagazineForm();
      this.$refs.magazinePopup.open();
    },
    resetMagazineForm() {
      Object.assign(this.magazine, {
        link: null,
        releaseNumber: null
      });
    },
    async onMagazinePopupSubmit() {
      try {
        let res = null;
        const magazinePayload = {
          ...this.magazine,
          releaseNumber: Number(this.magazine.releaseNumber)
        };
        if (this.magazinePopupTitle == 'إضافة عدد جديد') {
          res = await zadiRepo.createMagazine(magazinePayload);
        } else {
          res = await zadiRepo.updateMagazine(magazinePayload);
        }

        if (res.status === 201 || res.status === 200) {
          this.$refs.magazinePopup.close();
          await this.gridApi.refreshServerSideStore({ purge: true });
        }
      } catch (err) {
        this.$refs.magazinePopup.endLoading();
      }
    },
    async getAllMagazines(query) {
      return await zadiRepo.fetchMagazines(query);
    },
  },
  created() {
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};
</script>